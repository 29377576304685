import React, { useEffect, useState } from "react";
import Styles from "./index.module.scss";
import { useNavigate } from "react-router-dom";
import { getLicensingContract, login } from "../../utils/axiosURL.js";
import copy from "copy-to-clipboard";
import Logo from "../../assets/logo.png";
import { Dialog, Toast } from "antd-mobile";
import { useDispatch } from "react-redux";
import { _toggleLanguage } from "../../reducer/toggleLanguage.js";

const Index = () => {
    const dispatch = useDispatch();
    const navigete = useNavigate();
    const [wc, setWc] = useState('')
    // 截取地址栏推荐人钱包地址
    const presenter = window.location.href.split("?").length === 2 ?
        window.location.href.split("?")[1].replace(/utm_source=/, "").replace(/tokenpocket/, "").replace(/&tokenpocket/, "").replace(/&/, "") : ""
    useEffect(() => {
        if (!window.ethereum) {
            return Dialog.show({
                content: '请复制链接前往TP钱包中点击“发现”，在顶部搜索栏中粘贴打开！或者直接跳转TP app内打开。',
                closeOnAction: true,
                actions: [
                    [
                        {
                            key: 'copy',
                            text: '复制链接',
                            onClick: () => copy(window.location.href)
                        },
                        {
                            key: 'cancel',
                            text: '前往TP打开',
                            onClick: () => window.location.href = 'tpdapp://open?params={"url": "https://www.wukongbscnft.pro/", "chain": "BNB", "source":"xxx"}'
                        }
                    ],
                ],
            })
        }
        window.ethereum.request({ method: 'eth_requestAccounts' }).then(res => {
            localStorage.setItem('account', res[0])
            let addressStr = res[0].split('')
            let prev = addressStr.slice(0, 4)
            let next = addressStr.slice(addressStr.length - 4, addressStr.length)
            setWc([...prev, '...', ...next].join(''))
            try {
                getLicensingContract().then(({ data }) => {
                    localStorage.setItem('licensingContract', data)
                    login({ bscAddress: window.injectTPAddress ?? localStorage.getItem('account'), peterId: presenter })
                        .then(data => {
                            dispatch(_toggleLanguage(true))
                            console.log(data, '注册成功的回调');
                        })
                        .catch(err => { console.log(err, '注册失败的回调') })
                }).catch(_ => { })
            } catch (err) { console.log(err); }
        }).catch(err => {
            Toast.show({
                icon: 'fail',
                duration: 2000,
                content: '连接钱包失败, 请尝试刷新',
            })
        })
    }, [])

    return (
        <div className={Styles['navbar']}>
            <img className={Styles.logo} src={Logo} onClick={() => navigete("/")} alt="" />
            <div className={Styles.logoIn}>{wc !== '' ? wc : "连接钱包"}</div>
        </div>
    )
}

export default Index;
